define("discourse/plugins/discourse-salesforce/discourse/initializers/salesforce-topic-list-class", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    api.modifyClass("component:topic-list-item", dt7948.p({
      pluginId: "discourse-salesforce",
      unboundClassNames() {
        let classList = this._super(...arguments);
        if (this.topic.has_accepted_answer) {
          classList += " salesforce-case";
        }
        return classList;
      }
    }, [["method", "unboundClassNames", [(0, _decorators.default)()]]]));
  }
  var _default = _exports.default = {
    name: "salesforce-topic-list-class",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.1.0", initializeWithApi);
    }
  };
});